import axios from 'axios';
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'first-visit': localStorage.getItem('first-visit') === null
    }
});
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['first-visit'] = localStorage.getItem('first-visit') === null
    localStorage.setItem('first-visit', 'false')
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
export default instance
