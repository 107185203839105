import React, {Component} from 'react';

//Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template CSS Style
import "../src/style.css";
import "./assets/css/responsive.css";
import "../src/rtl.css";
import "./assets/css/color/color-default.css";
// import "@fontsource/tajawal";

//Import Component
import Layout from "./Layout";
import ReactLoading from "react-loading";

require('dotenv').config()

class App extends Component {
    render() {
        setTimeout(() => {
            try {
                document.getElementById('mainLoader').style.display = 'none'
            } catch (e) {
                console.error(e)
            }
        }, 1500)
        document.body.style.backgroundImage = "url('"+process.env.REACT_APP_API_ENDPOINT.slice(0, -5)+"/uploads/fixed/background_img.png?"+ Math.random()+"')"
        return (
            <div className="App">
                <div className="initLoader" id="mainLoader">
                    <ReactLoading type={"spinningBubbles"} color="#C79D52" className={"spinner"}/>
                </div>
                <Layout/>
            </div>
        );
    }
}

export default App;
