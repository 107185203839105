import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {Link} from "react-scroll";
import {LinkContainer} from "react-router-bootstrap";
import {Navbar, Container, Nav, Accordion, Button, Card} from "react-bootstrap";
import SearchModal from "./SearchModal";
import Icofont from "react-icofont";
import Tooltip from "@material-ui/core/Tooltip";

class NavBar extends Component {


    constructor(props) {
        super(props);
        this.state = {
            megaMenuDataState: props.megaMenuData
        }
        this.handleClickedSubject = this.handleClickedSubject.bind(this)
        // this.setState({megaMenuDataState: this.props.megaMenuData})
    }

    componentDidMount() {
        this.setState({megaMenuDataState: this.props.megaMenuData})
        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("menu-shrink");
                elem.classList.add("fixed-top");
            } else {
                elem.classList.remove("menu-shrink");
                elem.classList.remove("fixed-top");
            }
        });
        window.scrollTo(0, 0);
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({megaMenuDataState: nextProps.megaMenuData})
    }

    handleClickedSubject(event) {
        const selectedSubject = event;
        const megaMenuData = this.state.megaMenuDataState;
        const newData = megaMenuData.map(item => {
            if (item.id === selectedSubject.id) {
                return {...item, subjectClicked: true}
            }
            return {...item, subjectClicked: false}
        })
        this.setState({megaMenuDataState: newData})
    }

    handleClickSearch = () => {
        this.props.history.push("/search");
    }


    render() {
        const {megaMenuDataState} = this.state

        const megaMenuData = megaMenuDataState.map((item, index) => {
            return (
                <div key={index}>
                    {/*//add tooltip to the long text*/}
                    <Tooltip title={item.title}  placement="top">
                    <li onClick={() => this.handleClickedSubject(item)}
                        className={item.subjectClicked ? 'loadSub activeMega' : 'loadSub '}>
                        {item.title}
                    </li>
                    </Tooltip>

                    <div className={item.subjectClicked ? 'sub-menu  showSubmenu' : 'sub-menu '}>

                        {
                            item.serieses.map((ser, index) => {
                                return <LinkContainer to={'/series-material/' + ser.slug} key={index}>
                                    {/*//add tooltip to the long text*/}
                                  <Tooltip title={ser.title} placement="top">

                                    <Nav.Link
                                        className={item.serieses.length > 4 ? 'text-overflow w-50' : 'text-overflow'}>

                                        {ser.title}

                                        </Nav.Link>
                                  </Tooltip>

                                </LinkContainer>
                            })
                        }
                        <LinkContainer to={'/type-series/' + item.slug} className="">
                            <Nav.Link className="text-overflow">المزيد...</Nav.Link>
                        </LinkContainer>
                    </div>
                </div>
            )
        })
        const megaMenuDataMobile = this.props.megaMenuData.map((item, index) => {
            return (
                <Nav.Item key={index}>
                    <LinkContainer to={'/type-series/' + item.slug}>
                        <Nav.Link
                            className="smooths nav-link dropdownMega"
                            onClick={this.closeNavbar}>
                            {item.title}
                        </Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            )
        })
        return (
            <React.Fragment>
                {/* Start Top Header */}
                <div className="top-header">
                    <div className="container">

                    </div>
                </div>
                {/* End Top Header */}

                <Navbar
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-md navbar-light"
                    collapseOnSelect={true}
                >
                    <Container>
                        <Navbar.Brand className="navbar-brand logo">
                            <React.Fragment>
                                <LinkContainer exact to="/">
                                    <img
                                        src={this.props.MainLogo}
                                        alt="Logo"
                                    />
                                </LinkContainer>
                            </React.Fragment>
                        </Navbar.Brand>

                        <Navbar.Brand className="navbar-brand-logo2 logo-2">
                            <React.Fragment>
                                <LinkContainer exact to="/">
                                    <img
                                        className="img-fluid"
                                        src={this.props.MainLogo}
                                        alt="Logo"
                                    />
                                </LinkContainer>
                            </React.Fragment>
                        </Navbar.Brand>

                        <div className="header-search-responsive" onClick={this.handleClickSearch}>
                            <Icofont icon="icofont-search-1"/>
                        </div>

                        <div className="header-live-stream-responsive">
                            <LinkContainer to="/live">
                                <button className="btn"><img
                                    src={this.props.live}
                                    alt="البث المباشر"
                                    style={{
                                        width: "20px",
                                        height: "20px"
                                    }}
                                /> بث مباشر!
                                </button>
                            </LinkContainer>
                        </div>
                        <Navbar.Toggle
                            className="navbar-toggler"
                            type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            id="collaspe-btn"
                        />

                        <Navbar.Collapse
                            id="navbarSupportedContent"
                        >
                            <Nav className="navbar-nav ml-auto">
                                <React.Fragment>
                                    <Nav.Item>
                                        <LinkContainer to="/home">
                                            <Nav.Link activeclass="active">الصفحة الرئيسية</Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item className="megaOnDesktop">
                                        <LinkContainer to="/type-series/All">
                                            <Nav.Link className="dropdownMega"
                                                      activeclass="active"
                                            >
                                                المواد
                                            </Nav.Link>

                                        </LinkContainer>
                                        <div className={megaMenuData.length > 5 ? 'mega' : 'mega halfWidth'}>
                                            {megaMenuData.length > 0 && megaMenuData}
                                            {!megaMenuData.length && <span>جاري التحميل ...</span>}
                                        </div>
                                    </Nav.Item>
                                    <Accordion className="megaOnMobile">
                                        <Card>
                                            <Card.Header>
                                                <Nav.Item>
                                                    <LinkContainer to="/type-series/All">
                                                        <Link
                                                            activeclass="active"
                                                            to="home"
                                                            spy={true}
                                                            smooth={true}
                                                            offset={-200}
                                                            duration={800}
                                                            className="smooths nav-link dropdownMega"
                                                            onClick={this.closeNavbar}>
                                                            المواد
                                                        </Link>
                                                    </LinkContainer>
                                                </Nav.Item>
                                                <Accordion.Toggle style={{
                                                    position: 'absolute',
                                                    top: '8px',
                                                    left: '30%'
                                                }} as={Button} variant="link" eventKey="-2">
                                                    <i className="icofont-rounded-down"/>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="-2">

                                                <Card.Body>
                                                    {megaMenuDataMobile}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <Nav.Item>
                                        <LinkContainer to="/questions-categories">
                                            <Nav.Link activeclass="active">
                                                أسئلة واستشارات
                                            </Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <LinkContainer to="/about-doctor">
                                            <Nav.Link activeclass="active">
                                                الشيخ الطبيب
                                            </Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <LinkContainer to="/contact-us">
                                            <Nav.Link activeclass="active">
                                                تواصل معنا
                                            </Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                </React.Fragment>
                            </Nav>
                        </Navbar.Collapse>

                        <div className="header-search" onClick={this.handleClickSearch}>
                            <SearchModal/>
                        </div>

                        <div className="header-live-stream">
                            <LinkContainer to="/live">
                            <button className="btn"><img
                                src={this.props.live}
                                alt="البث المباشر"
                                style={{
                                    width: "20px",
                                    height: "20px"
                                }}
                            /> بث مباشر!
                            </button>
                            </LinkContainer>
                        </div>


                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

//Default Props
NavBar.defaultProps = {
    MainLogo: process.env.REACT_APP_API_ENDPOINT.slice(0, -5) + '/uploads/fixed/logo.png?' + Math.random(),
    live: require('../assets/img/play-button.png'),
};

export default withRouter(NavBar);
