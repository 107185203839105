window._mfq = window._mfq || [];

  (function() {

    var mf = document.createElement("script");

    mf.type = "text/javascript"; mf.defer = true;

    mf.src = "//cdn.mouseflow.com/projects/3a4c45e1-468c-4ee4-8a1c-2e4c80de5c42.js";

    document.getElementsByTagName("head")[0].appendChild(mf);

  })();