import React, {Component} from 'react';
import loadable from '@loadable/component'
//Import Component
import {Route} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import ScrollUpBtn from "./components/ScrollUp";
import api from "./api";
import Helmet from "react-helmet";

import NavBar from './components/NavBar'
const Footer = loadable(() => import('./components/Footer'))
const Main = loadable(() => import('./Main'))


class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            megaMenuData: [],
        };
        // this.handleClickedSubject = this.handleClickedSubject.bind(this)
        this.loadMegaMenuData();
    }

    loadMegaMenuData = () => {
        let self = this;
        self.state.isFetching = true;
        api.get('subjects',)
            .then(function (response) {
                self.state.megaMenuData = response.data.data.map(item => {
                    return {...item, subjectClicked: false}
                });
                self.setState({
                    megaMenuData: response.data.data.map(item => {
                        return {...item, subjectClicked: false}
                    })
                });
                self.state.isFetching = false;
            }).catch(function (error) {
            console.log("subjects error response :: ", error);
        });
    };

    render() {
        const {megaMenuData} = this.state
        return (
            <div>
                <Helmet>
                    <title>Dr.ALShaal</title>
                    {/*<meta name="description"        content="$OG_DESCRIPTION" />*/}
                    {/*<meta property="og:title"       content="$OG_TITLE" />*/}
                    {/*<meta property="og:description" content="$OG_DESCRIPTION" />*/}
                    {/*<meta property="og:image"       content="https://i.imgur.com/V7irMl8.png" />*/}
                    {/*<meta charSet="utf-8" />*/}
                    {/*<link rel="canonical" href="http://mysite.com/example" />*/}
                </Helmet>
                <Route
                    render={({location}) => (
                        <CSSTransition
                            key={location.key}
                            timeout={{enter: 900, exit: 900}}
                            classNames="fade"
                        >
                            <section className="route-section">
                                <NavBar subjectClicked={this.handleClickedSubject} megaMenuData={megaMenuData}
                                        pageName="home"/>
                                <div className="contentDiv">
                                    <Main location={location}/>
                                </div>
                                <Footer/>
                            </section>
                        </CSSTransition>
                    )}
                />
                {/* ScrollUpBtn: src/components/ScrollUp.jsx */}
                <ScrollUpBtn/>
            </div>
        );
    }
}

export default withRouter(Layout);
