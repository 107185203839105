import React, {Component} from "react";
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import Icofont from "react-icofont";

class ScrollUpBtn extends Component {
    state = {};

    componentDidMount() {
        document.getElementsByTagName('aside')[0].style.textAlignLast = 'center'
        document.getElementsByTagName('aside')[0].style.padding= '4px'
        document.getElementsByTagName('aside')[0].innerHTML = '<i class="icofont-swoosh-up" style="font-size: 28px"/>'
    }

    render() {
        return (
            <React.Fragment>
                <ScrollUpButton
                    style={{
                        padding: 8,
                        width: 40,
                        backgroundColor: "transparent",
                        color: '#6e5835',
                        height: 40,
                        border: "2px solid #6e5835",
                        outline: 0,
                        zIndex: 9999,
                    }}
                />
            </React.Fragment>
        );
    }
}

export default ScrollUpBtn;
